<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {
    let CNZZ_ID = "1281338756";
    // 创建cnzz统计js
    const script = document.createElement("script");
    script.src = `https://s9.cnzz.com/z.js?id=${CNZZ_ID}&async=1`;
    script.language = "JavaScript";
    document.body.appendChild(script);
  },
  watch: {
    $route: {
      handler() {
        setTimeout(() => {
          //避免首次获取不到window._czc
          if (window._czc) {
            let location = window.location;
            let contentUrl = location.pathname + location.hash;
            // let refererUrl = "/";
            // 用于发送某个URL的PV统计请求，
            window._czc.push(["_trackPageview", contentUrl, ""]);
          }
        }, 300);
      },
      immediate: true, // 首次进入页面即执行
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

import request from "@/utils/request";
// 校验用户是否登录
export function isToken(params) {
  return request({
    url: "/check/token",
    method: "get",
    params,
  });
}
// 是否是付费会员
export function isPaidMember(params) {
  return request({
    url: "/check/member/isPaidMember",
    method: "get",
    params,
  });
}

// 会员购买
export function memberPay(data) {
  return request({
    url: "/check/member/pay",
    method: "post",
    data,
  });
}
// 用户通知信息-列表
export function userNoticeList(params) {
  return request({
    url: "/check/userNotice/list",
    method: "get",
    params,
  });
}

// 用户通知信息-详情
export function userNoticeDetail(params) {
  return request({
    url: "/check/userNotice/detail",
    method: "get",
    params,
  });
}
// 用户通知信息-未读状态
export function userNoticeUnread(params) {
  return request({
    url: "/check/userNotice/unread",
    method: "get",
    params,
  });
}
//用户通知信息-标记已读
export function userNoticeRead(data) {
  return request({
    url: "/check/userNotice/read",
    method: "post",
    data,
  });
}
// 是否支付保证金
export function isPaidDeposit(params) {
  return request({
    url: "/check/depositInfo/isPaidDeposit",
    method: "get",
    params,
  });
}

// 缴纳保证金
export function paycash(data) {
  return request({
    url: "/check/depositInfo/pay",
    method: "post",
    data,
  });
}
// 保证金退还
export function paybackcash(data) {
  return request({
    url: "/check/depositInfo/withdraw",
    method: "post",
    data,
  });
}
// 用户中心-交易大厅-挂牌信息列表
export function listingInfoList(params) {
  return request({
    url: "/check/listingInfo/list",
    method: "get",
    params,
  });
}

// 用户中心-交易大厅-挂牌信息详情
export function listingInDetail(params) {
  return request({
    url: "/check/listingInfo/detail",
    method: "get",
    params,
  });
}

//用户中心-交易大厅-挂牌信息详情-历史记录列表
export function listingLogList(params) {
  return request({
    url: "/check/listingInfo/logList",
    method: "get",
    params,
  });
}
// 挂牌信息价格修改
export function changePrice(data) {
  return request({
    url: "/check/listingInfo/changePrice",
    method: "post",
    data,
  });
}

// 挂牌信息数量修改
export function changeQuantity(data) {
  return request({
    url: "/check/listingInfo/changeQuantity",
    method: "post",
    data,
  });
}
// 挂牌信息最小摘牌数量修改
export function changeMinQuantity(data) {
  return request({
    url: "/check/listingInfo/changeMinQuantity",
    method: "post",
    data,
  });
}

//挂牌信息报价截止时间修改
export function changeQuotationDeadlineTime(data) {
  return request({
    url: "/check/listingInfo/changeQuotationDeadlineTime",
    method: "post",
    data,
  });
}

//挂牌信息联系信息修改
export function changeContact(data) {
  return request({
    url: "/check/listingInfo/changeContact",
    method: "post",
    data,
  });
}
// 仅发布
export function changePublish(data) {
  return request({
    url: "/check/listingInfo/publish",
    method: "post",
    data,
  });
}
// 仅下架
export function offShelf(data) {
  return request({
    url: "/check/listingInfo/offShelf",
    method: "post",
    data,
  });
}
// 付费会员详情
export function memberDetail(params) {
  return request({
    url: "/check/member/detail",
    method: "get",
    params,
  });
}

//用户中心-交易大厅-报价信息列表
export function quotationList(params) {
  return request({
    url: "/check/quotation/list",
    method: "get",
    params,
  });
}

//用户中心-交易大厅-订单列表 &&  用户中心-交易大厅-摘牌列表
export function orderList(params) {
  return request({
    url: "/check/order/list",
    method: "get",
    params,
  });
}
// 用户中心-交易大厅-摘牌详情
export function orderDetail(params) {
  return request({
    url: "/check/order/detail",
    method: "get",
    params,
  });
}
// 用户中心-交易大厅-报价详情按挂牌信息id查询
export function xunjia(params) {
  return request({
    url: "/check/quotation/detailByListingInfo",
    method: "get",
    params,
  });
}
// 获取订单信息服务费
export function calculateOrderServiceFee(params) {
  return request({
    url: "/check/order/calculateOrderServiceFee",
    method: "get",
    params,
  });
}
// 去支付
export function goTobuy(data) {
  return request({
    url: "/check/order/pay",
    method: "post",
    data,
  });
}
// 去交付
export function goTodeliver(data) {
  return request({
    url: "/check/order/deliver",
    method: "post",
    data,
  });
}
// 确认收货
export function goTodecomfirm(data) {
  return request({
    url: "/check/order/confirmReceipt",
    method: "post",
    data,
  });
}
// 报价确认
export function baojiacomfim(data) {
  return request({
    url: "/check/quotation/confirm",
    method: "post",
    data,
  });
}
// 用户中心-个人信息
export function getUserDetail(params) {
  return request({
    url: "/check/personalInformation/detail",
    method: "get",
    params,
  });
}
// 用户中心-个人信息修改
export function setUserDetail(data) {
  return request({
    url: "/check/personalInformation/changeContact",
    method: "post",
    data,
  });
}

// 个人用户信息
export function getInfo(params) {
  return request({
    url: "/user/person/detail",
    method: "get",
    params,
  });
}
// 用户保存
export function userSave(data) {
  return request({
    url: "/user/save",
    method: "post",
    data,
  });
}
// 用户列表
export function getUserList(params) {
  return request({
    url: "/user/list",
    method: "get",
    params,
  });
}

// 删除用户
export function userDelets(data) {
  return request({
    url: "/user/delete",
    method: "post",
    data,
  });
}
// 登出
export function logout() {
  return request({
    url: "/logout",
    method: "post",
  });
}

// 用户修改密码
export function changePassword(data) {
  return request({
    url: "/person/changePassword",
    method: "post",
    data,
  });
}

// 用户信息
export function changeUserInfo(data) {
  return request({
    url: "/person/changeInfo",
    method: "post",
    data,
  });
}

// 批量重置密码
export function resetPassword(data) {
  return request({
    url: "/user/resetPassword",
    method: "post",
    data,
  });
}
// 更改用户状态
export function changeStatus(data) {
  return request({
    url: "/user/changeStatus",
    method: "post",
    data,
  });
}
// 企业信息
export function getCompanyInfo(params) {
  return request({
    url: "/check/companyInfo/detail",
    method: "get",
    params,
  });
}
// 驳回原因
export function getCompanyReject(params) {
  return request({
    url: "/check/companyInfo/rejectRemark",
    method: "get",
    params,
  });
}
// 公司信息提交
export function submitCompanyInfo(data) {
  return request({
    url: "/check/companyInfo/submit",
    method: "post",
    data,
  });
}

// 公司注销
export function deregisterCompanyInfo(data) {
  return request({
    url: "/check/companyInfo/deregister",
    method: "post",
    data,
  });
}

//是否与公司认证相同
export function getCompanyisSame(params) {
  return request({
    url: "/check/listingInfo/isSame",
    method: "get",
    params,
  });
}

// 产品试用-信息保存
export function publishCompanyInfo(data) {
  return request({
    url: "/check/productUsage/save",
    method: "post",
    data,
  });
}

// 产品试用-信息校验
export function checkCompanyInfo(params) {
  return request({
    url: "/check/productUsage/check",
    method: "get",
    params,
  });
}

<template>
  <div class="home">
    <!-- 导航  -->
    <Header :class="{ 'fixed-header': isFixed }"></Header>
    <router-view v-slot="{ Component }">
      <component :is="Component" :key="key"
    /></router-view>
    <!-- 底部 -->
    <Bottom></Bottom>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Bottom from "@/components/Bottom.vue";
import router from "../router";
export default {
  name: "Home",
  components: {
    Header,
    Bottom,
  },
  data() {
    return {
      bannerIndex: 0,
      serverIndex: 0,
      banTimer: null,
      isFixed: false,
    };
  },
  computed: {
    key() {
      return router.currentRoute.path;
    },
  },
  mounted() {
    this.startBanner();
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    handleScroll() {
      // 获取滚动距离
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // console.log(scrollTop, "dddd");
      // 根据滚动距离判断是否固定头部
      this.isFixed = scrollTop > 72;
    },
    bannerOver() {
      clearInterval(this.banTimer);
    },
    bannerOut() {
      clearInterval(this.banTimer);
      this.startBanner;
    },
    startBanner() {
      this.banTimer = setInterval(() => {
        this.bannerIndex++;
        if (this.bannerIndex === 3) {
          this.bannerIndex = 0;
        }
        if (this.bannerIndex === -1) {
          this.bannerIndex = 2;
        }
      }, 5000);
    },

    leftClick() {
      this.bannerIndex--;
      if (this.bannerIndex === -1) {
        this.bannerIndex = 2;
      }
    },
    rightClick() {
      this.bannerIndex++;
      if (this.bannerIndex === 3) {
        this.bannerIndex = 0;
      }
    },
    indexClick(val) {
      this.bannerIndex = val;
    },
    serveClick(val) {
      this.serverIndex = val;
    },
  },
};
</script>

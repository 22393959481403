import axios from "axios";
import { Message } from "element-ui"; //MessageBox ,
import store from "@/store";
// import { getToken } from '@/utils/auth'
import { getStoreLocal } from "@/utils/store";
// import router from "../router";
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // baseURL: '', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000, // request timeout
});
service.defaults.timeout = 200000;
// request interceptor
service.interceptors.request.use(
  (config) => {
    if (store.getters.token) {
      config.headers["Authorization"] = getStoreLocal({ name: "token" });
    }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 0) {
      // Message({
      //   message: res.msg || 'Error',
      //   type: 'error',
      //   duration: 5 * 1000
      // })
      // 50008: Illegal token; 50012: Other clients logged in; 2001: Token expired;
      // if (res.code === -2001) {
      //   // to re-login
      //   MessageBox.confirm("您的登录状态已失效，请重新登录以继续操作。", {
      //     confirmButtonText: "重新登录",
      //     cancelButtonText: "取消",
      //     showClose: false,
      //     closeOnClickModal: false,
      //     showCancelButton: false,
      //     type: "warning",
      //   }).then(() => {
      //     store.dispatch("user/resetToken").then(() => {
      //       // location.reload();
      //       router.push({ path: "/login" });
      //     });
      //   });
      // }
      // 系统未知错误
      if (res.code === -1000) {
        Message({
          message: res.message || "Error",
          type: "error",
          duration: 5 * 1000,
        });
        return res;
      }
      // 未授权
      if (res.code === 403) {
        // return res.code
        Message({
          message: res.message || "Error",
          type: "error",
          duration: 5 * 1000,
        });
        return res;
      }

      // 公司绑定信息判断
      if (res.code === 600) {
        // return res.code
        // Message({
        //   message: res.message || 'Error',
        //   type: 'error',
        //   duration: 5 * 1000
        // })
        return res;
      }
      // 公司绑定信息判断
      if (res.code === 700) {
        // return res.code
        // Message({
        //   message: res.message || 'Error',
        //   type: 'error',
        //   duration: 5 * 1000
        // })
        return res;
      }
      if (res.code === 400) {
        // return res.code
        // Message({
        //   message: res.message || 'Error',
        //   type: 'error',
        //   duration: 5 * 1000
        // })
        return res;
      }
      // return res
      if (res.code === -1) {
        return res;
      }
      return res;
      // return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res;
    }
  },
  (error) => {
    console.log("err" + error); // for debug
    if (error.message.includes("timeout")) {
      Message({
        message: "请求服务超时",
        type: "error",
        duration: 5 * 1000,
      });
    } else {
      if (error.response.status === 500) {
        Message({
          message: "服务器错误",
          type: "error",
          duration: 5 * 1000,
        });
      }
      if (error.response.status === 404) {
        Message({
          message: "资源未找到" || error.response.error || "Error",
          type: "error",
          duration: 5 * 1000,
        });
      }
      if (error.response.status === 401) {
        Message({
          message: "未授权" || error.response.error || "Error",
          type: "error",
          duration: 5 * 1000,
        });
      }
      if (error.response.status === 403) {
        Message({
          message: "禁止访问" || error.response.error || "Error",
          type: "error",
          duration: 5 * 1000,
        });
      }
      return Promise.reject(error);
    }
  }
);

export default service;

<template>
  <div class="header">
    <div class="center">
      <div class="logo" @click="handleLoginClick">
        <img src="../assets/logo.jpg" alt="" />
      </div>
      <div class="container">
        <ul class="outer">
          <!-- <li :class="{ active: linkIndex === 0 }" @click="routerLink('/', 0)">
            首页
            <b class="bactive"></b>
          </li> -->
          <li :class="{ active: linkIndex === 1 }" @click="routerLink('/', 1)">
            <span>交易大厅</span>
            <b class="bactive"></b>
          </li>
          <!-- <li
            class="gloass"
            :class="{ active: linkIndex === 2 }"
            @click="routerLink('/industry', 2)"
          >
            <span>行业</span>
            <span class="down"><img src="../assets/down.png" alt="" /></span>
            <span class="up"><img src="../assets/up.png" alt="" /></span>
          </li> -->

          <!-- <li
            class="gloass"
            :class="{ active: linkIndex === 3 }"
            @click="routerLink('/business', 3)"
          >
            <span>碳交易</span>
            <span class="down"><img src="../assets/down.png" alt="" /></span>
            <span class="up"><img src="../assets/up.png" alt="" /></span>
          </li> -->
          <li
            class="gloass teshu"
            :class="{ active: linkIndex === 4 }"
            @click="routerLink('/product', 4)"
          >
            <span>产品服务</span>
            <b class="bactive"></b>
            <span class="down"><img src="../assets/down.png" alt="" /></span>
            <span class="up"><img src="../assets/up.png" alt="" /></span>
            <div class="gloassChild">
              <div class="tips">碳管理</div>
              <div class="tipsChild" @click.stop="handInProductDetail(0)">
                <span class="icon1"></span>
                <h2>碳核算</h2>
                <b>智能碳核算SaaS平台</b>
              </div>
              <div class="tipsChild" @click.stop="handInProductDetail(1)">
                <span class="icon2"></span>
                <h2>碳资产管理</h2>
                <b>智能碳核算SaaS服务平台</b>
              </div>
              <div class="tipsChild" @click.stop="handInProductDetail(4)">
                <span class="icon3"></span>
                <h2>碳核算大脑</h2>
                <b>碳核算大脑PaaS系统</b>
              </div>

              <div class="tips">碳监测</div>
              <div class="tipsChild" @click.stop="handInProductDetail(3)">
                <span class="icon4"></span>
                <h2>碳测量</h2>
                <b>连续在线测量系统(CEMS)</b>
              </div>
              <div class="tipsChild" @click.stop="handInProductDetail(2)">
                <span class="icon5"></span>
                <h2>无人机监测</h2>
                <b>无人机在线监测系统</b>
              </div>
              <div class="tipsChild" @click.stop="handInProductDetail(5)">
                <span class="icon6"></span>
                <h2>卫星监测</h2>
                <b>空天地立体监测系统</b>
              </div>

              <div class="tips">碳工具</div>
              <div class="tipsChild" @click.stop="handInProductDetail(6)">
                <span class="icon7"></span>
                <h2>碳监测传感器</h2>
                <b>碳监测专用传感器</b>
              </div>
              <div class="tipsChild" @click.stop="handInProductDetail(7)">
                <span class="icon8"></span>
                <h2>因子库</h2>
                <b>电力行业排放因子数据库</b>
              </div>
              <div class="tipsChild">
                <span></span>
                <h2></h2>
                <b></b>
              </div>
            </div>
          </li>
          <li
            class="gloass"
            :class="{ active: linkIndex === 2 }"
            @click="routerLink('/finance', 2)"
          >
            <span>绿色金融</span>
            <b class="bactive"></b>
            <!-- <span class="down"><img src="../assets/down.png" alt="" /></span>
            <span class="up"><img src="../assets/up.png" alt="" /></span> -->
          </li>
          <li
            class="gloass"
            :class="{ active: linkIndex === 6 }"
            @click="routerLink('/review', 6)"
            style="width: 80px"
          >
            <span>绿色评测</span>
            <b class="bactive"></b>
            <!-- <span class="down"><img src="../assets/down.png" alt="" /></span>
            <span class="up"><img src="../assets/up.png" alt="" /></span> -->
          </li>

          <li
            class="gloass"
            :class="{ active: linkIndex === 7 }"
            @click="routerLink('/peixun', 7)"
            style="width: 80px"
          >
            <span>双碳培训</span>
            <b class="bactive"></b>
          </li>

          <li
            class="gloass"
            :class="{ active: linkIndex === 5 }"
            @click="routerLink('/consult', 5)"
            style="width: 176px"
          >
            <span>教育部碳中和虚拟教研室</span>
            <b class="bactive"></b>
            <!-- <span class="down"><img src="../assets/down.png" alt="" /></span>
            <span class="up"><img src="../assets/up.png" alt="" /></span> -->
          </li>

          <!-- <li
            class="gloass"
            :class="{ active: linkIndex === 5 }"
            @click="routerLink('/serve', 5)"
          >
            <span>绿色服务</span>
            <span class="down"><img src="../assets/down.png" alt="" /></span>
            <span class="up"><img src="../assets/up.png" alt="" /></span>
          </li> -->
          <!-- <li
            :class="{ active: linkIndex === 3 }"
            @click="routerLink('/research', 3)"
          >
            人才培训
            <b class="bactive"></b>
          </li> -->
        </ul>
      </div>
      <div class="helpsright" @click="goToHelps" v-show="!isout">
        <svg-icon icon-class="helps" />
      </div>
      <!-- 已登录 -->
      <div class="rightmenu" :class="{ ishiden: isout }">
        <el-popover
          placement="bottom-start"
          trigger="click"
          @show="getFetchNoticeList"
          v-model="isnotice"
        >
          <!-- 用户通知 -->
          <div class="usernotice">
            <div v-show="!isdetail">
              <div v-if="noticeList.length">
                <div class="notice_header">
                  <div class="nleft">
                    消息<span>（共{{ total }}条）</span>
                  </div>
                  <div class="nright" @click="getUserRead('all')">一键已读</div>
                </div>
                <div class="notice_body">
                  <div
                    class="body_demo"
                    :class="{ haveread: item.noticeStatus === 2 }"
                    style="margin-bottom: 10px; cursor: pointer"
                    v-for="item in noticeList"
                    :key="item.id"
                    @click="gotoReadNotice(item)"
                  >
                    <div class="bdoy_left">
                      <img
                        v-if="item.noticeType == 1"
                        src="../assets/systemnotice.png"
                        alt=""
                      />
                      <img v-else src="../assets/monthnotice.png" alt="" />
                    </div>
                    <div class="bdoy_right">
                      <div class="rtop">
                        <div class="rtop_left">
                          {{ item.noticeType === 1 ? "系统通知" : "月报推送" }}
                        </div>
                        <div class="rtop_right">
                          {{ formatDate(item.noticeTime) }}
                        </div>
                      </div>
                      <div
                        class="rtbot"
                        v-show="item.noticeType === 1"
                        style="font-weight: 200"
                      >
                        {{ item.message }}
                      </div>
                      <div
                        class="rtbot"
                        v-show="item.noticeType !== 1"
                        style="font-weight: 200"
                      >
                        {{ item.title }}
                      </div>
                    </div>
                  </div>
                  <div class="minifenye">
                    <el-pagination
                      background
                      @size-change="handleSizeChange"
                      @current-change="handleCurrentChange"
                      :current-page="currentPage"
                      :page-sizes="[5, 10, 15]"
                      :page-size="pageSize"
                      layout="prev, pager, next, jumper"
                      :pager-count="5"
                      :total="total"
                      :small="true"
                    >
                    </el-pagination>
                  </div>
                </div>
              </div>
              <div class="empty" v-else>
                <div class="notice_header">
                  <div class="nleft">消息</div>
                </div>
                <div class="wenzi" style="font-size: 14px">暂无消息</div>
              </div>
            </div>
            <div class="noticeDetail" v-show="isdetail">
              <div class="notice_header">
                <div class="nleft lvnleft" @click="gotoList">返回消息列表</div>
              </div>
              <div class="notice_header">
                <div class="nleft">{{ detailObj.title }}</div>
                <div class="nrightc">
                  {{ formatDate(detailObj.noticeTime) }}
                </div>
              </div>
              <div class="wztext">
                {{ detailObj.message }}
              </div>
            </div>
          </div>
          <div class="notive" slot="reference">
            <b v-show="isnoticeStatus"></b>
            <svg-icon icon-class="notice" />
          </div>
        </el-popover>
        <b class="fenge"></b>
        <div class="person" @click="handInUser">
          <img :src="avatar" alt="" />
        </div>

        <el-dropdown class="avatar-container" trigger="click">
          <div class="avatar-wrapper">
            <div class="information">
              <el-tooltip
                class="item"
                effect="light"
                :content="name"
                :disabled="calculateStringLength(name) > 10 ? false : true"
                placement="top"
              >
                <span class="name">{{ name }}</span>
              </el-tooltip>
              <!-- 之前是companyName,现在username -->
              <el-tooltip
                class="item"
                effect="light"
                :content="username"
                :disabled="calculateStringLength(username) > 16 ? false : true"
                placement="top"
              >
                <span class="inf">{{ username }}</span>
              </el-tooltip>
              <!-- <span class="more"><b /><b /><b /></span> -->
            </div>
          </div>
          <el-dropdown-menu slot="dropdown" class="user-dropdown">
            <el-dropdown-item @click.native="handInUser">
              <span style="display: block">用户中心</span>
            </el-dropdown-item>
            <el-dropdown-item @click.native="handInVip">
              <span style="display: block">会员中心</span>
            </el-dropdown-item>
            <el-dropdown-item divided @click.native="logout">
              <span style="display: block">退出</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <!-- 未登录 -->
      <div class="login" :class="{ ishiden: !isout }" @click="handleLogin()">
        <div class="helpsright helpsrightno" @click="goToHelps" v-show="isout">
          <svg-icon icon-class="helps" />
        </div>
        <span>登录/注册</span>
      </div>
    </div>
  </div>
</template>

<script>
// import { getStore, setStore } from "@/utils/store";
// import { mapGetters } from "vuex";
import { getStoreLocal } from "@/utils/store";
import {
  userNoticeList,
  isToken,
  userNoticeUnread,
  userNoticeRead,
  userNoticeDetail,
} from "@/api/user.js"; //tradeLatest
import store from "@/store";
import { mapState } from "vuex";

export default {
  name: "Header",
  props: {
    // msg: String,
  },
  computed: {
    ...mapState({
      linkIndex: (state) => state.user.linkIndex,
    }),
  },
  data() {
    return {
      pageCount: 2,
      isnotice: false,
      userNoticeIds: [],
      detailObj: {},
      isdetail: false,
      timerId: null,
      isnoticeStatus: false,
      noticeList: [],
      // linkIndex: 0,
      gossShow: false,
      avatar: "",
      name: "",
      username: "",
      token: "",
      isout: true,
      currentPage: 1,
      pageSize: 5,
      total: 0,
      // 1xitong,2yuebao
    };
  },
  watch: {
    $route: function () {
      // console.log(this.$route, "aaaaa");
      this.initRoute();
    },
    token(newValue) {
      // console.error(newValue, oldValue);
      if (newValue) {
        this.isout = false;
      } else {
        this.isout = true;
      }
    },
  },
  created() {
    // 从本地拿取信息
    this.avatar = getStoreLocal({ name: "avatar" }) || "";
    this.name = getStoreLocal({ name: "name" }) || "";
    this.username = getStoreLocal({ name: "username" }) || "";
    this.token = getStoreLocal({ name: "token" }) || "";
    this.initRoute();
    isToken().then((res) => {
      if (res.code === 0 && res.message === "success") {
        // 定时刷新状态
        this.startTimer();
      } else {
        this.stopTimer();
      }
    });
  },
  destroyed() {
    // 在页面销毁时清除定时器和接口请求
    this.stopTimer();
  },
  methods: {
    // 帮助中心
    goToHelps() {
      this.$router.push({
        path: "/helps",
      });
    },
    handInProductDetail(val) {
      this.$router.push({
        path: "/product/productdetail?pageIndex=page" + val,
      });
    },
    // 读取信息
    gotoReadNotice(item) {
      this.userNoticeIds.push(item.id);
      if (item.noticeType === 1) {
        // 系统通知
        userNoticeDetail({
          noticeId: item.id,
        })
          .then((res) => {
            if (res.code === 0) {
              this.isdetail = true;
              this.detailObj = res.data;
            } else {
              this.$message({
                message: res.message,
                type: "warning",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      // 月报
      // console.error(item);
      if (item.noticeType === 2) {
        this.$router.push({
          path: "/monthreport?reportId=" + item.relationId,
        });
        this.isnotice = false;
      }
    },
    // 返回上一级
    gotoList() {
      this.isdetail = false;
      this.getUserRead("sigle");
    },
    // 标记已读
    getUserRead(val) {
      if (val === "all") {
        this.userNoticeIds = this.noticeList.map((item) => {
          return item.id;
        });
      }
      userNoticeRead({
        userNoticeIds: this.userNoticeIds,
      })
        .then((res) => {
          if (res.code === 0) {
            console.log("已读", res);
            this.getNoticeList();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    startTimer() {
      this.timerId = setInterval(() => {
        userNoticeUnread()
          .then((res) => {
            if (res.code === 0) {
              // console.error("未读请求");
              this.isnoticeStatus = res.data.result;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }, 10000);
    },
    stopTimer() {
      if (this.timerId) {
        clearInterval(this.timerId);
      }
    },
    // 显示列表
    getFetchNoticeList() {
      this.currentPage = 1;
      this.getNoticeList();
    },
    // 消息列表
    getNoticeList() {
      userNoticeList({
        currentPage: this.currentPage,
        pageSize: this.pageSize,
      })
        .then((res) => {
          if (res.code === 0) {
            this.noticeList = res.data.list;
            // console.error("消息列表", res);
            this.currentPage = res.data.currentPage;
            this.total = res.data.total;
          } else {
            if (res.code === -2001) {
              this.$messagebox
                .confirm("请登录以继续操作。", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  showClose: false,
                  closeOnClickModal: false,
                  showCancelButton: true,
                  type: "warning",
                })
                .then(() => {
                  store.dispatch("user/resetToken").then(() => {
                    // location.reload();
                    this.$router.push({ path: "/login" });
                  });
                });
            } else {
              this.$message({
                message: res.message,
                type: "warning",
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handInUser() {
      this.$router.push({ path: "/user" });
      this.$store.dispatch("select/clearUserSelect", "");
    },
    handInVip() {
      this.$router.push({ path: "/members" });
    },
    handleLogin() {
      this.$router.push({ path: "/login" });
    },
    handleChild() {
      this.gossShow = true;
    },
    hiddenChild() {
      this.gossShow = false;
    },
    routerLink(val, num) {
      if (val === "/consult") {
        // 跳转
        window.open("http://tanetong.com/jiaoyanshi/", "_blank");
        return;
      } else {
        let currentPath = this.$router.currentRoute.fullPath;
        // console.error("当前====", currentPath, val);
        if (currentPath === val) {
          return;
        } else {
          this.$router.push(val);
          // this.linkIndex = num;
          store.dispatch("user/pachLinkIndex", num);
        }
      }
    },
    initRoute() {
      let currentPath = this.$router.currentRoute.fullPath;
      let arry = [
        "/user",
        "/productvip",
        "/usergpdetail",
        "/userdddetail",
        "/userzpdetail",
        "/buydetail",
        "/market",
        "/monthreport",
        "/peopledetail",
        "/peoplenewsdetail",
        "/newsDetail",
        "/buy",
      ];
      let issome = arry.some((item) => {
        return currentPath.includes(item);
      });
      if (issome) {
        store.dispatch("user/pachLinkIndex", 1);
      }

      if (currentPath.includes("/productdetail")) {
        store.dispatch("user/pachLinkIndex", 4);
      }
      switch (currentPath) {
        // case "/":
        //   // this.linkIndex = 0;
        //   store.dispatch("user/pachLinkIndex", 0);
        //   break;
        case "/":
          // this.linkIndex = 1;
          store.dispatch("user/pachLinkIndex", 1);
          break;
        case "/helps":
          // this.linkIndex = 1;
          store.dispatch("user/pachLinkIndex", 1);

          break;
        case "/user":
          // this.linkIndex = 1;
          store.dispatch("user/pachLinkIndex", 1);

          break;
        case "/buylist":
          // this.linkIndex = 1;
          store.dispatch("user/pachLinkIndex", 1);

          break;
        case "/tradetail":
          // this.linkIndex = 1;
          store.dispatch("user/pachLinkIndex", 1);

          break;
        case "/members":
          // this.linkIndex = 1;
          store.dispatch("user/pachLinkIndex", 1);

          break;
        case "/professor":
          // this.linkIndex = 1;
          store.dispatch("user/pachLinkIndex", 1);

          break;
        case "/videos":
          // this.linkIndex = 1;
          store.dispatch("user/pachLinkIndex", 1);

          break;
        case "/finance":
          // this.linkIndex = 2;
          store.dispatch("user/pachLinkIndex", 2);

          break;
        case "/research":
          // this.linkIndex = 3;
          store.dispatch("user/pachLinkIndex", 3);

          break;
        case "/product":
          // this.linkIndex = 4;
          store.dispatch("user/pachLinkIndex", 4);

          break;
        // case "/product/productdetail":
        //   // this.linkIndex = 4;
        //   store.dispatch("user/pachLinkIndex", 4);

        //   break;
        case "/consult":
          // this.linkIndex = 5;
          store.dispatch("user/pachLinkIndex", 5);
          break;
        case "/review":
          // this.linkIndex = 5;
          store.dispatch("user/pachLinkIndex", 6);
          break;
        case "/peixun":
          store.dispatch("user/pachLinkIndex", 7);
          break;
        default:
          break;
        // case "/industry":
        //   this.linkIndex = 2;
        //   break;
        // case "/industry/industrydetail":
        //   this.linkIndex = 2;
        //   break;
        // case "/business":
        //   this.linkIndex = 3;
        //   break;
        // case "/news":
        //   this.linkIndex = 3;
        //   break;
        // case "/serve":
        //   this.linkIndex = 5;
        //   break;
      }
    },
    calculateStringLength(str) {
      let length = 0;
      if (str) {
        for (let i = 0; i < str.length; i++) {
          const charCode = str.charCodeAt(i);
          // 检查字符是否为汉字（Unicode 范围：[\u4e00-\u9fa5]）
          if (charCode >= 0x4e00 && charCode <= 0x9fa5) {
            length += 2; // 汉字占两个位置
          } else {
            length += 1; // 字母占一个位置
          }
        }
        return length;
      }
    },
    async logout() {
      await this.$store.dispatch("user/logout");
      this.isout = true;

      // const message = {
      //   type: "loginOut",
      // };
      // const targetOrigin = "http://localhost:9528/";
      // setTimeout(() => {
      //   window.parent.postMessage(message, targetOrigin);
      // }, 1000);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.getNoticeList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getNoticeList();
    },
    formatDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    handleLoginClick() {
      this.$router.push({
        path: "/",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
ul,
ol,
li,
menu {
  list-style: none;
}
.header {
  width: 100%;
  // background-color: #fff;
  height: 72px;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.6);
  // backdrop-filter: blur(4px);
}
.center {
  width: 1200px;
  height: 72px;
  position: relative;
  // background-color: #fff;
  // border: 1px solid red;
  margin: 0 auto;
  text-align: left;
  line-height: 72px;
  vertical-align: middle;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  .logo {
    width: 129px;
    line-height: 0px;
    cursor: pointer;
    img {
      width: 129px;
      vertical-align: top;
      margin-top: 12px;
    }
  }
  .container {
    // border: 1px solid blue;
    height: 72px;
    ul.outer {
      cursor: pointer;
      width: 710px;
      margin-left: 64px;
      // margin-right: 325px;
      // border: 1px solid red;
      // display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      white-space: nowrap;
      li {
        margin-right: 30px;
        font-size: 16px;
        color: rgba(3, 16, 43, 0.6);
        position: relative;
        width: 64px;
        display: inline-block;
        text-align: center;
        b.bactive {
          width: 40px;
          height: 3px;
          background: rgba(74, 171, 61, 1);
          display: none;
          position: absolute;
          left: 0px;
          right: 0px;
          top: 67px;
          bottom: 0px;
          margin: 0 auto;
        }
      }
      li:hover {
        color: rgba(3, 16, 43, 1);
        font-weight: 900;
        // border-bottom: vh(4) solid rgba(74, 171, 61, 1);
        // b.bactive {
        //   display: block;
        // }
      }

      li.teshu:hover {
        border-bottom: none;
      }
      li.gloass {
        position: relative;
        span.down,
        span.up {
          width: 24px;
          height: 24px;
          display: inline-block;
          line-height: 0;
          img {
            width: 100%;
          }
          position: absolute;
          right: -22px;
          top: 24px;
        }
        span.up {
          opacity: 0;
        }

        div.gloassChild {
          position: absolute;
          width: 1083px;
          height: 300px;
          background-color: #f8f8f8;
          left: -285px;
          top: 72px;
          text-align: center;
          display: none;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: flex-start;
          justify-content: flex-start;
          opacity: 0.95;
          .tips {
            width: 120px;
            height: 100px;
            background-color: rgba(74, 171, 61, 1);
            border-bottom: 1px solid #e8e8e872;
            text-align: center;
            color: #fff;
            font-size: 16px;
            line-height: 100px;
          }
          .tipsChild {
            width: 320px;
            height: 100px;
            background: rgb(251, 251, 251);
            border-right: 1px solid #efefef;
            border-bottom: 1px solid #efefef;
            position: relative;
            h2 {
              font-size: 16px;
              font-weight: 900;
              color: #333;
              position: absolute;
              left: 80px;
              top: 0px;
            }
            b {
              font-size: 12px;
              color: rgba(16, 38, 67, 0.6);
              position: absolute;
              left: 80px;
              top: 30px;
            }
            span {
              width: 24px;
              height: 24px;
              display: block;
              position: absolute;
              left: 43px;
              top: 26px;
            }

            span.icon1 {
              background-image: url("../assets/jisuan.png");
              background-size: cover;
            }
            span.icon2 {
              background-image: url("../assets/jisuan2.png");
              background-size: cover;
            }
            span.icon3 {
              background-image: url("../assets/jisuan3.png");
              background-size: cover;
            }
            span.icon4 {
              background-image: url("../assets/jisuan4.png");
              background-size: cover;
            }
            span.icon5 {
              background-image: url("../assets/jisuan5.png");
              background-size: cover;
            }
            span.icon6 {
              background-image: url("../assets/jisuan6.png");
              background-size: cover;
            }
            span.icon7 {
              background-image: url("../assets/jisuan7.png");
              background-size: cover;
            }
            span.icon8 {
              background-image: url("../assets/jisuan8.png");
              background-size: cover;
            }
          }
          .tipsChild:hover {
            h2 {
              color: #4aab3d;
            }
          }
        }
      }
      li.gloass:hover {
        span.down {
          opacity: 0;
        }
        span.up {
          opacity: 1;
        }
        div.gloassChild {
          display: flex;
        }
      }
      li.teshu {
        padding-right: 22px;
        width: 82px;
        span.down,
        span.up {
          right: 0px;
        }
        b.bactive {
          left: -18px;
        }
      }
      li.show {
        pointer-events: none;
      }
      li.active {
        color: rgba(3, 16, 43, 1);
        font-weight: 900;
        // border-bottom: vh(4) solid rgba(74, 171, 61, 1);
        b.bactive {
          display: block;
        }
      }
    }
  }
  .helpsright {
    display: block;
    width: 24px;
    // vertical-align: middle;
    // border: 1px solid red;
    margin-top: 6px;
    margin-left: 58px;
    cursor: pointer;
    svg {
      width: 24px;
      height: 24px;
    }
  }
  .helpsrightno {
    display: inline-block;
    margin-right: 32px;
  }
  .login {
    cursor: pointer;
    margin-left: auto;
    span {
      width: 111px;
      height: 40px;
      border-radius: 4px;
      background: rgba(74, 171, 61, 1);
      color: #fff;
      font-size: 14px;
      text-align: center;
      display: inline-block;
      vertical-align: top;
      line-height: 40px;
      margin-top: 16px;
    }
  }
  .rightmenu {
    // border: 1px solid red;
    display: block;
    // width: 213px;
    width: 194px;
    cursor: pointer;
    position: relative;
    margin-left: auto;
    padding-top: 9px;
    .notive {
      // border: 1px solid blue;
      float: left;
      line-height: 0;
      position: relative;
      cursor: pointer;
      svg {
        width: 24px;
        height: 24px;
      }
      b {
        width: 6px;
        height: 6px;
        display: block;
        position: absolute;
        right: 2px;
        top: 2px;
        border: 1px solid #fff;
        background-color: #f24827;
        border-radius: 50%;
      }
      margin-top: 15px;
    }
    b.fenge {
      width: 1px;
      height: 24px;
      background-color: #e7efec;
      display: block;
      float: left;
      margin-left: 24px;
      margin-right: 24px;
      margin-top: 15px;
    }
    .person {
      margin-top: 10px;
      line-height: 0;
      float: left;
      img {
        width: 32px;
        height: 32px;
        border-radius: 50px;
      }
    }

    .avatar-container {
      position: absolute;
      right: 0px;
      top: 18px;
    }
    .avatar-wrapper {
      margin-top: 8px;
      .information {
        line-height: 0;
        // width: 106px;
        float: left;
        min-width: 73px;
        max-width: 106px;
        margin-left: 12px;
        // border: 1px solid blue;
        height: 50px;
        position: relative;
        margin-top: -19px;
        span.name {
          width: 100%;
          display: block;
          line-height: 25px;
          font-size: 14px;
          color: #03102b;
          font-weight: 400;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          // border: 1px solid red;
          height: 25px;
          position: absolute;
          left: 0px;
          top: 5px;
        }
        span.inf {
          width: 100%;
          height: 25px;
          // border: 1px solid red;
          display: block;
          line-height: 25px;
          font-size: 12px;
          color: #83879b;
          // overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          position: absolute;
          bottom: 0px;
          left: 0px;
        }
      }
      span.more {
        // border: 1px solid red;
        position: absolute;
        right: 0px;
        top: 19px;
        b {
          width: 3px;
          height: 3px;
          background-color: #9f9f9f;
          border-radius: 50%;
          display: block;
          margin-bottom: 5px;
        }
      }
    }
  }
  .ishiden {
    display: none;
  }
  z-index: 30;
}
.fixed-header {
  // width: 100%;
  // height: 72px;
  // position: fixed;
  // top: 0;
  // left: 0;
  // z-index: 100;
  background-color: rgba(255, 255, 255);
}
</style>

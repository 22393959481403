import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "../src/icons/index.js";
// import "@/styles/element-variables.scss";
// import "../src/styles/element-variables.scss";
import "element-ui/lib/theme-chalk/index.css";
import "../src/styles/element-variables.scss";
import {
  Pagination,
  DatePicker,
  Select,
  Option,
  Tooltip,
  OptionGroup,
  Button,
  Input,
  Dialog,
  Form,
  Row,
  Col,
  FormItem,
  Checkbox,
  Message,
  MessageBox,
  Notification,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Table,
  TableColumn,
  Drawer,
  Popover,
  Scrollbar,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Upload,
  Cascader,
  RadioGroup,
  Radio,
  // Tabs,
  // TabPane,
} from "element-ui"; // 按需引入组件

import VideoPlayer from "vue-video-player"; // 视频播放器
import "video.js/dist/video-js.css";
// import { detectZoom } from "@/utils/detectZoom.js";
// import "./utils/rem.js";

Vue.component(Pagination.name, Pagination); // 注册全局组件
Vue.component(DatePicker.name, DatePicker); // 注册全局组件
Vue.component(Select.name, Select); // 注册全局组件
Vue.component(Option.name, Option); // 注册全局组件
Vue.component(OptionGroup.name, OptionGroup); // 注册全局组件
Vue.component(Button.name, Button); // 注册全局组件
Vue.component(Tooltip.name, Tooltip); // 注册全局组件
Vue.component(Input.name, Input); // 注册全局组件
Vue.component(Dialog.name, Dialog); // 注册全局组件
Vue.component(Form.name, Form); // 注册全局组件
Vue.component(FormItem.name, FormItem); // 注册全局组件
Vue.component(Row.name, Row); // 注册全局组件
Vue.component(Col.name, Col); // 注册全局组件
Vue.component(Checkbox.name, Checkbox); // 注册全局组件
Vue.component(Dropdown.name, Dropdown); // 注册全局组件
Vue.component(DropdownMenu.name, DropdownMenu); // 注册全局组件
Vue.component(DropdownItem.name, DropdownItem); // 注册全局组件
Vue.component(Table.name, Table); // 注册全局组件
Vue.component(TableColumn.name, TableColumn); // 注册全局组件
Vue.component(Drawer.name, Drawer); // 注册全局组件
Vue.component(Popover.name, Popover); // 注册全局组件
Vue.component(Menu.name, Menu); // 注册全局组件
Vue.component(Submenu.name, Submenu); // 注册全局组件
Vue.component(MenuItem.name, MenuItem); // 注册全局组件
Vue.component(MenuItemGroup.name, MenuItemGroup); // 注册全局组件
Vue.component(Upload.name, Upload); // 注册全局组件
Vue.component(Cascader.name, Cascader); // 注册全局组件
Vue.component(RadioGroup.name, RadioGroup); // 注册全局组件
Vue.component(Radio.name, Radio); // 注册全局组件

Vue.config.productionTip = false;
Vue.use(VideoPlayer);
// Vue.use(Message);
Vue.prototype.$message = Message;
Vue.prototype.$messagebox = MessageBox;
Vue.prototype.$notify = Notification;
Vue.use(Scrollbar);
// const m = detectZoom();
// console.log(
//   m,
//   "ddd",
//   window.screen.width * window.devicePixelRatio,
//   window.devicePixelRatio
// );
// switch (m) {
//   // 4k屏时屏幕缩放比为100%
//   case 100:
//     document.body.style.zoom = 100 / 50;
//     break;
//   // 4k屏时屏幕缩放比为125%
//   case 125:
//     document.body.style.zoom = 100 / 62.5;
//     break;
//   // 4k屏时屏幕缩放比为150%
//   case 150:
//     document.body.style.zoom = 100 / 75;
//     break;
//   // 4k屏时屏幕缩放比为175%
//   case 175:
//     document.body.style.zoom = 100 / 87.4715;
//     break;
//   // 4k屏时屏幕缩放比为200%
//   case 200:
//     document.body.style.zoom = 100 / 100;
//     break;
//   // 4k屏时屏幕缩放比为225%
//   case 225:
//     document.body.style.zoom = 100 / 112.485;
//     break;

//   default:
//     break;
// }
//判断屏幕是否为4k
// if (
//   window.screen.width * window.devicePixelRatio >= 3840 ||
//   window.devicePixelRatio === 2
// ) {
//   document.body.style.zoom = 100 / (Number(m) / 2);
// } else {
//   document.body.style.zoom = 100 / Number(m);
// }

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

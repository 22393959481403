import { logout } from "@/api/user"; //getInfo

// import { resetRouter } from "@/router";
import {
  setStoreLocal,
  getStoreLocal,
  // removeStoreLocal,
  clearStoreLocal,
} from "@/utils/store";
const getDefaultState = () => {
  return {
    // token: getToken(),
    token: getStoreLocal({ name: "token" }),
    user: getStoreLocal({ name: "user" }) || "", // token 中解析后用户的所有信息
    name: getStoreLocal({ name: "name" }) || "",
    companyName: "",
    avatar: getStoreLocal({ name: "avatar" }) || "",
    roles: [],
    username: getStoreLocal({ name: "username" }) || "",
    userId: null,
    linkIndex: 0,
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_COMPANY_NAME: (state, name) => {
    state.companyName = name;
  },
  SET_USER_ID: (state, id) => {
    state.userId = id;
  },
  SET_linkIndex: (state, linkIndex) => {
    state.linkIndex = linkIndex;
  },
  SET_USER_NAME: (state, name) => {
    state.username = name;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_USER: (state, user) => {
    state.user = user;
    setStoreLocal({ name: "user", content: user });
  },
};

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { token, user, password } = userInfo;
    const resultUser = user.replace(/\\/g, "");
    const parseUser = JSON.parse(resultUser);
    // console.error(userInfo, "用户信息====", parseUser);
    const userId = parseUser.id;
    // console.error('userInfo', parseUser)
    // parseUser.avatar = 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif'
    return new Promise((resolve, reject) => {
      if (token) {
        commit("SET_TOKEN", token);
        commit("SET_USER", parseUser);
        // setToken(token)
        setStoreLocal({ name: "token", content: token });
        setStoreLocal({ name: "password", content: password });
        setStoreLocal({ name: "userId", content: userId });
        resolve();
      } else {
        reject();
      }
    });
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve) => {
      const res = {};
      const rolesKey = [];
      const data = state.user;
      const { roles, nickname, username, authorities } = data;
      // console.error('用户信息=====', data)
      // username, authorities,
      for (const key in roles) {
        rolesKey.push(key);
      }
      // 深拷贝data
      res.roles = rolesKey;
      res.authorities = authorities;
      commit("SET_ROLES", rolesKey);
      commit("SET_NAME", nickname);
      commit("SET_USER_NAME", username);

      // 暂时用的头像
      const avatar =
        "https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif";
      commit("SET_AVATAR", avatar);

      setStoreLocal({ name: "name", content: nickname });
      setStoreLocal({ name: "username", content: username });
      setStoreLocal({ name: "avatar", content: avatar });
      // getInfo()
      //   .then((res) => {
      //     // console.error(res, '个人信息====')
      //     if (res.code === 0) {
      //       const avatar = res.data.avatarUrl;
      //       const companyName = res.data.companyName;
      //       commit("SET_AVATAR", avatar);
      //       commit("SET_COMPANY_NAME", companyName);
      //     } else {
      //       this.$message({
      //         message: res.message,
      //         type: "warning",
      //       });
      //     }
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
      resolve(res);
    });
  },

  // user logout 退出登录
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      logout()
        .then(() => {
          clearStoreLocal();
          // removeToken() // must remove  token  first
          // removeStoreLocal({ name: "token" });
          // removeStoreLocal({ name: "password" });
          // removeStoreLocal({ name: "userId" });

          // removeStoreLocal({ name: "name" });
          // removeStoreLocal({ name: "username" });
          // removeStoreLocal({ name: "avatar" });
          //   resetRouter();
          commit("RESET_STATE");
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  pachLinkIndex({ commit }, val) {
    commit("SET_linkIndex", val);
  },
  // 用户名更新
  pachName({ commit }, userInfo) {
    const { nickname, username, companyName, avatar } = userInfo;
    commit("SET_NAME", nickname);
    commit("SET_USER_NAME", username);
    commit("SET_AVATAR", avatar);
    commit("SET_COMPANY_NAME", companyName);
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      clearStoreLocal();
      // removeToken() // must remove  token  first
      // removeStoreLocal({ name: "token" });
      commit("RESET_STATE");
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

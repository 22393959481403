const getters = {
  token: (state) => state.user.token,
  avatar: (state) => state.user.avatar,
  companyName: (state) => state.user.companyName,
  name: (state) => state.user.name,
  username: (state) => state.user.username,
  roles: (state) => state.user.roles,
  linkIndex: (state) => state.user.linkIndex,

  tradeproductType: (state) => state.select.tradeproductType,
  tradeproductLocation: (state) => state.select.tradeproductLocation,
  tradedeliveryType: (state) => state.select.tradedeliveryType,
  tradeproductYear: (state) => state.select.tradeproductYear,
  tradeprojectType: (state) => state.select.tradeprojectType,
  tradeprojectProductiveTime: (state) =>
    state.select.tradeprojectProductiveTime,
};
export default getters;

import { validatenull } from "@/utils/validate";

/**
 * 存储localStorage
 */
export const setStoreLocal = (params = {}) => {
  const { name, content, type } = params;
  const obj = {
    dataType: typeof content,
    content: content,
    type: type,
    datetime: new Date().getTime(),
  };
  window.localStorage.setItem(name, JSON.stringify(obj));
  // if (type) {
  //   window.sessionStorage.setItem(name, JSON.stringify(obj))
  // } else {
  //   window.localStorage.setItem(name, JSON.stringify(obj))
  // }
};
/**
 * 获取localStorage
 */

export const getStoreLocal = (params = {}) => {
  const { name, debug } = params;
  let obj = {};
  let content;
  obj = window.sessionStorage.getItem(name);
  if (validatenull(obj)) obj = window.localStorage.getItem(name);
  if (validatenull(obj)) return;
  try {
    obj = JSON.parse(obj);
  } catch (e) {
    return obj;
  }
  if (debug) {
    return obj;
  }
  if (obj.dataType === "string") {
    content = obj.content;
  } else if (obj.dataType === "number") {
    content = Number(obj.content);
  } else if (obj.dataType === "boolean") {
    // eslint-disable-next-line
    content = eval(obj.content)
  } else if (obj.dataType === "object") {
    content = obj.content;
  }
  return content;
};
/**
 * 删除localStorage
 */
export const removeStoreLocal = (params = {}) => {
  const { name } = params;
  window.localStorage.removeItem(name);
  // if (type) {
  //   window.sessionStorage.removeItem(name)
  // } else {
  //   window.localStorage.removeItem(name)
  // }
};

/**
 * 获取全部localStorage
 */
export const getAllStoreLocal = (params = {}) => {
  const list = [];
  const { type } = params;
  if (type) {
    for (let i = 0; i <= window.sessionStorage.length; i++) {
      list.push({
        name: window.sessionStorage.key(i),
        content: getStoreLocal({
          name: window.sessionStorage.key(i),
          type: "session",
        }),
      });
    }
  } else {
    for (let i = 0; i <= window.localStorage.length; i++) {
      list.push({
        name: window.localStorage.key(i),
        content: getStoreLocal({
          name: window.localStorage.key(i),
        }),
      });
    }
  }
  return list;
};

/**
 * 清空全部localStorage
 */
export const clearStoreLocal = () => {
  // const { type } = params
  // if (type) {
  window.sessionStorage.clear();
  // } else {
  window.localStorage.clear();
  // }
};

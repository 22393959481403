<template>
  <div class="bottomTop">
    <div class="bottomContent">
      <div class="left">
        <img class="lefttop" src="../assets/elog.png" />
        <div class="evm">
          <img src="../assets/evm.png" alt="" />
          <span>碳E通微信公众号</span>
        </div>
      </div>
      <div class="right">
        <p>北京山林云海科技有限公司</p>
        <p>地址：北京市昌平区北农路2号华北电力大学内</p>
        <p>邮编：100096</p>
        <p>电话：010-61771540</p>
        <p>邮箱：kefu@tanetong.com</p>
        <div class="rtspan">
          <span>Copyright @www.tanetong.com, All Rights Reserved. </span>
          <span style="margin-top: 10px"
            ><a
              style="color: rgba(255, 255, 255, 0.6)"
              href="https://beian.miit.gov.cn"
              >京ICP备2023017074号-1</a
            ><a
              style="color: rgba(255, 255, 255, 0.6); margin-left: 10px"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011402053816"
              >京公网安备11011402053816号</a
            ></span
          >
        </div>
      </div>
      <div class="rightom">
        <div class="wave">
          <img src="../assets/botomimg2.png" alt="" />
        </div>

        <div class="map">
          <img src="../assets/tiao.png" alt="" />
        </div>
        <div class="dipan">
          <img src="../assets/dipan.png" alt="" />
        </div>
        <div class="text">
          <h3>碳E通</h3>
          <p>华北电力大学内</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Bottom",
  // props: {
  //   msg: String,
  // },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.bottomTop {
  width: 100%;
  height: 340px;
  overflow: hidden;
  background: #112643;
  // border: 1px solid red;
  // background-size: cover;
  // margin-top: vh(100);
  .bottomContent {
    // border: 1px solid red;
    width: 1200px;
    margin: 0 auto;
    position: relative;
    // display: flex;
    // flex-direction: row;
    .left {
      width: 120px;
      float: left;
      margin-top: 44px;
      margin-left: 0px;
      img.lefttop {
        width: 120px;
        // height: vh(44);
        height: auto;
      }
      .evm {
        width: 120px;
        img {
          width: 120px;
          margin-top: 10px;
          margin-bottom: 10px;
        }
        span {
          font-size: 12px;
          text-align: center;
          width: 100%;
          display: block;
          color: #ffffff;
          opacity: 0.6;
        }
      }
    }
    .right {
      // border: 1px solid red;
      margin-left: 80px;
      margin-top: 44px;

      text-align: left;
      width: 293px;
      padding-left: 20px;
      float: left;
      white-space: nowrap;
      // border-left: 1px solid rgba(101, 115, 129, 0.4);
      p {
        font-size: 14px;
        color: #fff;
        line-height: 38px;
        opacity: 0.8;
      }
      .rtspan {
        margin-top: 34px;
        span {
          display: block;
          font-size: 12px;
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
    .rightom {
      width: 850px;
      // border: 1px solid red;

      position: absolute;
      right: 0px;
      top: 0px;
      .wave {
        width: 850px;
        height: 340px;
        position: absolute;
        right: -196px;
        top: 0px;
        // border: 1px solid red;
        img {
          width: 850px;
          height: 340px;
        }
      }
      .dipan {
        position: absolute;
        left: 623px;
        top: 172px;
        // border: 1px solid red;
      }
      .map {
        position: absolute;
        top: 122px;
        left: 607px;
        animation: movepoint 2s infinite;
        -webkit-animation: movepoint 2d inifinite;
      }
      .text {
        color: #fff;
        position: absolute;
        // border: 1px solid red;
        left: 595px;
        top: 198px;
        h3 {
          font-size: 14px;
        }
        p {
          font-size: 12px;
          color: #72777f;
          margin-top: 5px;
        }
      }
      @keyframes movepoint {
        45% {
          top: 128px;
        }
        100% {
          top: 0.7;
        }
      }
    }
  }
}
// .bottomTop {
//   width: 100%;
//   height: 345px;
//   overflow-y: hidden;
//   overflow-x: hidden;

//   background: #23374b;
//   // background-size: cover;
//   // margin-top: vh(100);
//   .bottomContent {
//     width: 1200px;
//     margin: 0 auto;
//     position: relative;
//     .left {
//       width: 120px;
//       float: left;
//       margin-top: 82px;
//       img.lefttop {
//         width: 114px;
//         height: auto;
//         display: inline-block;
//         vertical-align: middle;
//         margin-right: 10px;
//       }
//       span.leftspan {
//         font-size: 22px;
//         color: #7e8c99;
//         display: inline-block;
//         vertical-align: middle;
//         b {
//           margin-left: 10px;
//           margin-right: 10px;
//         }
//       }
//       .evm {
//         float: right;
//         width: 125px;
//         img {
//           width: 120px;
//         }
//       }
//     }
//     .right {
//       margin-left: 40px;
//       margin-top: 45px;
//       text-align: left;
//       width: 293px;
//       float: left;
//       border-left: 1px solid rgb(101, 115, 129, 0.4);
//       padding-left: 30px;

//       p {
//         font-size: 14px;
//         color: #fff;
//         line-height: 28px;
//         opacity: 0.6;
//       }
//       .rtspan {
//         margin-top: 20px;
//         span {
//           display: block;
//           font-size: 12px;
//           color: rgba(255, 255, 255, 0.6);
//         }
//       }
//     }
//     .rightom {
//       width: 790px;
//       // border: 1px solid red;

//       position: absolute;
//       right: -260px;
//       top: 40px;
//       .wave {
//         width: 818px;
//         height: 350px;
//         position: absolute;
//         left: -140px;
//         top: -41px;
//         img {
//           width: 818px;
//           height: 350px;
//         }
//       }
//       .dipan {
//         position: absolute;
//         left: 263px;
//         top: 100px;
//         // border: 1px solid red;
//       }
//       .map {
//         position: absolute;
//         top: 70px;
//         left: 243px;
//         animation: movepoint 2s infinite;
//         -webkit-animation: movepoint 2d inifinite;
//       }
//       .text {
//         color: #fff;
//         position: absolute;
//         // border: 1px solid red;
//         left: 200px;
//         top: 180px;
//         h3 {
//           font-size: 14px;
//         }
//         p {
//           font-size: 12px;
//           color: #72777f;
//           margin-top: 5px;
//         }
//       }
//       @keyframes movepoint {
//         45% {
//           top: 65px;
//         }
//         100% {
//           top: 70px;
//         }
//       }
//     }
//   }
// }
</style>

const getDefaultState = () => {
  return {
    tradeproductType: "",
    tradeproductLocation: "",
    tradedeliveryType: "",
    tradeproductYear: "",
    tradeprojectType: "",
    tradeprojectProductiveTime: "",

    userProductType: "",
    userProductLocation: "",
    userDeliveryType: "",
    userProductYear: "",
    userListingStatus: "",
    userProjectType: "",
    userProjectProductiveTime: "",
  };
};

const state = getDefaultState();

const mutations = {
  SET_productType: (state, productType) => {
    state.tradeproductType = productType;
  },
  SET_productLocation: (state, productLocation) => {
    state.tradeproductLocation = productLocation;
  },
  SET_deliveryType: (state, deliveryType) => {
    state.tradedeliveryType = deliveryType;
  },
  SET_productYear: (state, productYear) => {
    state.tradeproductYear = productYear;
  },
  SET_projectType: (state, projectType) => {
    state.tradeprojectType = projectType;
  },
  SET_projectProductiveTime: (state, projectProductiveTime) => {
    state.tradeprojectProductiveTime = projectProductiveTime;
  },
  //   user
  SET_userProductType: (state, userProductType) => {
    state.userProductType = userProductType;
  },
  SET_userProductLocation: (state, userProductLocation) => {
    state.userProductLocation = userProductLocation;
  },
  SET_userDeliveryType: (state, userDeliveryType) => {
    state.userDeliveryType = userDeliveryType;
  },
  SET_userListingStatus: (state, userListingStatus) => {
    state.userListingStatus = userListingStatus;
  },
  SET_userProductYear: (state, userProductYear) => {
    state.userProductYear = userProductYear;
  },
  SET_userProjectType: (state, userProjectType) => {
    state.userProjectType = userProjectType;
  },
  SET_userProjectProductiveTime: (state, userProjectProductiveTime) => {
    state.userProjectProductiveTime = userProjectProductiveTime;
  },
};

const actions = {
  pachProductType({ commit }, val) {
    commit("SET_productType", val);
  },
  pachProductLocation({ commit }, val) {
    commit("SET_productLocation", val);
  },
  pachDeliveryType({ commit }, val) {
    commit("SET_deliveryType", val);
  },
  pachProductYear({ commit }, val) {
    commit("SET_productYear", val);
  },
  pachProjectType({ commit }, val) {
    commit("SET_projectType", val);
  },
  pachProjectProductiveTime({ commit }, val) {
    commit("SET_projectProductiveTime", val);
  },
  clearPachSelect({ commit }, val) {
    commit("SET_productType", val);
    commit("SET_productLocation", val);
    commit("SET_deliveryType", val);
    commit("SET_productYear", val);
    commit("SET_projectType", val);
    commit("SET_projectProductiveTime", val);
  },

  // 用户参数
  pach_userProductType({ commit }, val) {
    commit("SET_userProductType", val);
  },
  pach_userProductLocation({ commit }, val) {
    commit("SET_userProductLocation", val);
  },
  pach_userDeliveryType({ commit }, val) {
    commit("SET_userDeliveryType", val);
  },
  pach_userListingStatus({ commit }, val) {
    commit("SET_userListingStatus", val);
  },
  pach_userProductYear({ commit }, val) {
    commit("SET_userProductYear", val);
  },
  pach_userProjectType({ commit }, val) {
    commit("SET_userProjectType", val);
  },
  pach_userProjectProductiveTime({ commit }, val) {
    commit("SET_userProjectProductiveTime", val);
  },
  clearUserSelect({ commit }, val) {
    commit("SET_userProductType", val);
    commit("SET_userProductLocation", val);
    commit("SET_userDeliveryType", val);
    commit("SET_userListingStatus", val);
    commit("SET_userProductYear", val);
    commit("SET_userProjectType", val);
    commit("SET_userProjectProductiveTime", val);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
